import type { GetStaticPaths, GetStaticProps } from 'next';
import type { SinglePostProps } from 'lib/queries/blog';
import type { FeaturedExpert, Author } from 'types';
import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { Link, Paragraph, Wrap, useToast } from '@leafwell/components';
import ArticleJson from 'components/structured-data/article';
import AuthorInfo from 'components/author-info';
import CardsGrid from 'components/cards-grid';
import WysiwygContent from 'components/wysiwyg-content';
import Navbar from 'components/navbar';
import Figure from 'components/figure';
import HeadingBody from 'components/heading-body';
import LinkArrow from 'components/link-arrow';
import Loader from 'components/loader';
import PageWrap from 'components/page-wrap';
import Blocks from 'components/blocks';
import { useEventsTracker } from 'contexts/eventsTracker';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { getSinglePost } from 'lib/queries/blog';

const ContentsTable = dynamic(() => import('components/contents-table'));
const NewsletterForm = dynamic(() => import('components/newsletter-form'), {
  ssr: false,
  loading: () => {
    //to prevent layout shift
    return (
      <Wrap
        id="newsletter-signup"
        size="large"
        className="h-[494px] sm:h-[512px] md:h-[330px] lg:h-[272px]"
      />
    );
  },
});

const SharePost: React.FC<{
  uri: string;
  title?: string;
}> = ({ uri, title }) => {
  const { translations } = useWordPressContext();
  const { trackEvent } = useEventsTracker();
  const { addToast } = useToast();

  return (
    <aside>
      <Paragraph
        className="pb-1"
        size="small"
        dangerouslySetInnerHTML={{
          __html: translations['Share this'],
        }}
      />
      <Link
        href={`https://www.facebook.com/sharer/sharer.php?u=${uri}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() =>
          trackEvent({
            event: 'share',
            method: 'facebook',
            content_type: title,
            item_id: 'blog_post',
          })
        }
      >
        <Image
          src="/social/facebook.svg"
          alt="facebook"
          className="inline-block hover:opacity-20 transition duration-150 mr-5 lg:mr-3"
          width={20}
          height={20}
        />
      </Link>
      <Link
        href={`https://twitter.com/intent/tweet?url=${uri}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() =>
          trackEvent({
            event: 'share',
            method: 'twitter',
            content_type: title,
            item_id: 'blog_post',
          })
        }
      >
        <Image
          src="/social/twitter.svg"
          alt="twitter"
          className="inline-block hover:opacity-20 transition duration-150 mr-5 lg:mr-3"
          width={20}
          height={16}
        />
      </Link>
      <Link
        onClick={async () => {
          addToast({
            content: 'Link copied to clipboard',
            key: 'link-copied',
            type: 'positive',
            duration: 2000,
            shouldRemove: true,
          });
          if ('clipboard' in navigator) {
            trackEvent({
              event: 'share',
              method: 'link',
              content_type: title,
              item_id: 'blog_post',
            });
            return await navigator.clipboard.writeText(uri);
          } else {
            trackEvent({
              event: 'share',
              method: 'link',
              content_type: title,
              item_id: 'blog_post',
            });
            return document.execCommand('copy', true, uri);
          }
        }}
      >
        <Image
          src="/link.svg"
          alt="copy url"
          className="inline-block hover:opacity-20 transition duration-150 mr-5 lg:mr-3"
          width={14}
          height={16}
        />
      </Link>
    </aside>
  );
};

const SinglePostContent: React.FC<SinglePostProps> = ({
  post: {
    id,
    date,
    modified,
    seo,
    title,
    content,
    featuredMedia,
    categories,
    postAuthor,
    acf: { blocks, relatedArticles, reviewer, featuredExperts },
  },
  tableOfContents,
}) => {
  const { translations } = useWordPressContext();
  const hasAuthor = postAuthor?.length;
  const hasFeaturedExperts = featuredExperts?.length;
  const pageType = 'post';

  return (
    <PageWrap seo={seo} title={title} pageType={pageType}>
      <Wrap
        size="large"
        className="pb-0 lg:grid-cols-12 lg:gap-x-12 xl:grid-cols-16 items-start"
        tagName="article"
      >
        <ArticleJson
          id={id}
          seo={seo}
          date={date}
          title={title}
          modified={modified}
          postAuthor={postAuthor}
        />
        <header className="grid w-full max-w-4xl lg:col-span-full justify-self-center gap-y-10 lg:gap-y-12">
          <div className="max-w-2xl justify-self-center">
            <HeadingBody level="2" tagName="h1" title={title} />
          </div>
          <Figure
            {...featuredMedia}
            className="aspect-w-16 aspect-h-6"
            objectFit="cover"
            priority={true}
            sizes="(min-width: 1024px) 50vw, 50vw"
          />
        </header>
        <div className="lg:sticky lg:top-40 lg:pl-4 lg:order-2 lg:col-span-4 xl:order-4 xl:sticky">
          {(hasAuthor || reviewer || hasFeaturedExperts) && (
            <Wrap
              size="large"
              spacer={false}
              className="block px-0 lg:!px-0 lg:pl-4 mb-6 md:mb-8 py-0 md:py-0 pb-6 md:pb-8 border-b border-indigo-200"
            >
              <div className="flex justify-between">
                <div className="flex-1 mr-4">
                  {hasAuthor > 0 && (
                    <section>
                      <Paragraph className="font-medium pb-2" size="small">
                        {translations['Article written by']}
                      </Paragraph>
                      {postAuthor.map((author: Author, key: number) => {
                        const hasBottomMargin = key < hasAuthor - 1;
                        return (
                          <AuthorInfo
                            {...author}
                            hasBottomMargin={hasBottomMargin}
                            variant="small"
                            key={author.id}
                          />
                        );
                      })}
                    </section>
                  )}
                  {reviewer && (
                    <section>
                      <Paragraph className="font-medium pt-4 pb-2" size="small">
                        {translations['Content reviewed by']}
                      </Paragraph>
                      <AuthorInfo {...reviewer} variant="small" />
                    </section>
                  )}
                  {hasFeaturedExperts && (
                    <section>
                      <Paragraph className="font-medium pt-4 pb-2" size="small">
                        {translations['Featured experts']}
                      </Paragraph>
                      {featuredExperts.map((author: FeaturedExpert) => {
                        const { expert } = author;
                        return (
                          <AuthorInfo
                            {...expert}
                            className="mb-4"
                            variant="small"
                            key={expert.id}
                          />
                        );
                      })}
                    </section>
                  )}
                </div>
                <div className="block lg:hidden">
                  <SharePost uri={seo?.canonical} title={title} />
                </div>
              </div>
            </Wrap>
          )}
          <ContentsTable items={tableOfContents} />
        </div>
        <span className="lg:col-span-8 xl:order-3 xl:col-start-5">
          <WysiwygContent className="" content={content} tagName="section" />
          {blocks.length > 0 ? (
            <span className="order-last leafwell-post-blocks">
              <Blocks blocks={blocks} modified={modified} pageType={pageType} />
            </span>
          ) : null}
        </span>
        <footer className="lg:order-3 lg:col-span-8 xl:order-2 xl:col-span-4 lg:pr-4 grid gap-y-6 xl:sticky xl:top-40">
          <SharePost uri={seo?.canonical} title={title} />
          {categories && categories.length > 0 && (
            <section className="grid gap-y-2">
              <Paragraph
                size="large"
                className="font-medium"
                dangerouslySetInnerHTML={{
                  __html: translations['Topics'],
                }}
              />
              <Navbar items={categories} classNameWrap="p-reset" />
            </section>
          )}
          {relatedArticles?.length > 0 && (
            <section className="hidden xl:grid gap-y-2">
              <Paragraph
                size="large"
                className="font-medium"
                dangerouslySetInnerHTML={{
                  __html: translations['Keep Reading'],
                }}
              />
              <ul className="grid gap-y-2">
                {relatedArticles.map(({ id, title, uri }, key: number) => (
                  <li key={id}>
                    <LinkArrow
                      showLine={key < relatedArticles.length - 1}
                      title={title}
                      uri={uri}
                    />
                  </li>
                ))}
              </ul>
            </section>
          )}
        </footer>
      </Wrap>
      {relatedArticles?.length > 0 && (
        <Wrap size="large">
          <CardsGrid
            items={relatedArticles.slice(0, 3)}
            variant="smallFeatured"
            title={translations['Keep Reading']}
          />
        </Wrap>
      )}
      <NewsletterForm filled />
    </PageWrap>
  );
};

const SinglePost: React.FC<SinglePostProps> = props => {
  const { isFallback } = useRouter();

  if (isFallback) {
    return <Loader />;
  }

  return <SinglePostContent {...props} />;
};

export const getStaticProps: GetStaticProps = (async context => {
  return await getSinglePost(context);
}) satisfies GetStaticProps<SinglePostProps>;

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

export default SinglePost;
