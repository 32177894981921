import type {
  UseScrollNavAction,
  UseScrollNavItem,
  UseScrollNavState,
} from 'hooks/useScrollNav';
import React, { forwardRef, memo, useEffect, useMemo, useRef } from 'react';
import { Link, Wrap, WrapProps } from '@leafwell/components';
import HeadingBody from './heading-body';
import { useActivePage } from '../hooks/useActivePage';
import { useScrollNav } from 'hooks/useScrollNav';
import { useEventsTracker } from 'contexts/eventsTracker';

export type NavbarLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  active?: boolean;
  displayH1?: boolean;
  name: string;
  size?: 'small' | 'default';
  isCategory?: boolean;
};

export type NavbarItemSettings = NavbarLinkProps & {
  uri: string;
};

export type NavbarInnerItemSettings = NavbarItemSettings &
  UseScrollNavItem & {
    onUpdate?: (active: UseScrollNavItem) => void;
  };

export type NavbarItemProps = Omit<NavbarLinkProps, 'active'> & {
  activeItem?: UseScrollNavState;
  setActiveItem?: UseScrollNavAction;
  isCategory?: boolean;
};

export type NavbarInnerItemProps = Omit<NavbarInnerItemSettings, 'uri'> &
  NavbarItemProps;

export type NavbarProps = Omit<WrapProps, 'children'> &
  Pick<NavbarLinkProps, 'displayH1' | 'size'> & {
    innerNav?: boolean;
    title?: string;
    items: (NavbarItemSettings | NavbarInnerItemSettings)[];
    offset?: number;
    variant?: 'minimal' | 'border';
    isCategory?: boolean;
    classNameWrap?: string;
    onUpdate?: (node: any) => void;
  };

export const NavbarLink: React.FC<NavbarLinkProps> = memo(
  forwardRef(
    (
      { active, displayH1, name, size = 'default', isCategory, ...props },
      ref,
    ) => {
      const Wrapper = active && displayH1 ? 'h1' : React.Fragment;
      const { trackEvent } = useEventsTracker();
      return (
        <Link
          className={[
            'underline-thickness-medium select-none no-drag',
            size === 'small'
              ? 'text-sm'
              : 'leading-loose underline-offset-medium',
            active ? '' : 'opacity-60',
          ].join(' ')}
          variant={active ? 'underlineHighlight' : 'underlineHover'}
          // @ts-ignore
          ref={ref}
          onClick={() => {
            if (isCategory) {
              trackEvent({
                event: 'select_content',
                content_type: name,
                item_id: 'category',
                origin: 'blog',
              });
            }
          }}
          {...props}
        >
          <Wrapper>{name}</Wrapper>
        </Link>
      );
    },
  ),
);

export const NavbarItem: React.FC<NavbarItemProps> = ({
  href,
  isCategory,
  ...props
}) => {
  const active = useActivePage(href);

  return (
    <NavbarLink
      active={active}
      href={href}
      isCategory={isCategory}
      {...props}
    />
  );
};

export const NavbarItemScroll: React.FC<NavbarInnerItemProps> = ({
  activeItem,
  id,
  onUpdate = () => null,
  setActiveItem,
  ...props
}) => {
  const el = useRef(null);
  const active = useMemo(() => activeItem === id, [activeItem, id]);

  useEffect(() => {
    if (active && el?.current) {
      onUpdate({ id, node: el.current });
    }
  }, [active, el, onUpdate]);

  return (
    <NavbarLink
      {...props}
      active={active}
      onClick={() => setActiveItem({ id, scroll: true })}
      // @ts-ignore
      ref={el}
    />
  );
};

const Navbar = (
  {
    className = '',
    classNameWrap = '',
    displayH1 = false,
    innerNav = false,
    items,
    offset,
    onUpdate,
    size,
    title,
    variant = 'minimal',
    isCategory = false,
    ...rest
  }: NavbarProps,
  ref,
) => {
  const [activeItem, setActiveItem] = useScrollNav(
    innerNav ? (items as NavbarInnerItemSettings[]) : [],
    offset,
  );
  const classNames = useMemo(() => {
    switch (variant) {
      case 'border':
        return {
          wrap: 'cursor-grab flex overflow-x-auto hide-scrollbar',
          nav: 'before:border-b before:border-b-gray-400 before:w-full before:absolute before:bottom-[2px] relative md:w-full flex-nowrap whitespace-nowrap gap-x-6',
        };
      default:
        return {
          wrap: '',
          nav: '',
        };
    }
  }, [variant]);

  return (
    <Wrap
      className={['gap-y-3 lg:gap-y-3', classNames.wrap, classNameWrap].join(
        ' ',
      )}
      ref={ref}
      size="large"
      spacer={false}
      {...rest}
    >
      {title && <HeadingBody level="5" title={title} />}
      <nav
        className={[
          'flex flex-wrap gap-y-2 gap-x-3 xl:gap-x-10',
          classNames.nav,
          className,
        ].join(' ')}
      >
        {items.map(({ uri, ...item }, key) => (
          <React.Fragment key={key}>
            {innerNav ? (
              // @ts-ignore
              <NavbarItemScroll
                {...item}
                displayH1={displayH1}
                href={uri}
                size={size}
                setActiveItem={setActiveItem}
                activeItem={activeItem}
                onUpdate={onUpdate}
              />
            ) : (
              <NavbarItem
                {...item}
                displayH1={displayH1}
                href={uri}
                size={size}
                isCategory={isCategory}
              />
            )}
          </React.Fragment>
        ))}
      </nav>
    </Wrap>
  );
};

export default forwardRef<HTMLDivElement, NavbarProps>(Navbar);
