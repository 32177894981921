import type { Page, PostExcerpt, Author } from 'types';
import React from 'react';
import { ArticleJsonLd } from 'next-seo';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { useShareImages } from 'hooks/useShareImages';

const ArticleJson: React.FC<
  | Omit<PostExcerpt, 'categories' | 'featuredImage' | 'link'>
  | (Pick<Page, 'id' | 'date' | 'modified' | 'seo'> & {
      postAuthor?: Author[];
    })
> = ({ date, id, modified, postAuthor, seo }) => {
  const {
    logo,
    siteTitle,
    seoSettings: { siteUrl },
  } = useWordPressContext();
  const image = useShareImages({
    title: siteUrl,
    type: 'simple',
  });
  let authorNames: string | string[] = 'Leafwell';

  if (postAuthor?.length) {
    authorNames = postAuthor?.map(({ name }) => name);
  }

  return (
    <ArticleJsonLd
      url={seo?.canonical}
      title={seo?.title}
      images={[image]}
      datePublished={date}
      dateModified={modified}
      authorName={authorNames}
      description={seo?.description}
      publisherLogo={logo?.src}
      publisherName={siteTitle}
      keyOverride={id}
    />
  );
};

export default ArticleJson;
