import { useCallback } from 'react';

import { debounce } from 'lib/utils/debounce';

export const useDebounce = <A = unknown, R = void>(
  fn: (args?: A) => R,
  ms = 200,
): ((args?: A) => Promise<R>) => {
  const debouncedFunction = useCallback(debounce<A, R>(fn, ms), []);

  return debouncedFunction;
};
