import { useCallback, useEffect, useMemo, useRef, useReducer } from 'react';
import { useGlobalStateContext } from 'contexts/global-state-context';
import { useDebounce } from 'hooks/useDebounce';

export type UseScrollNavItem = { id: string; node: Element };

export type UseScrollNavState = string | null;

export type UseScrollNavActionParams = {
  id: UseScrollNavState;
  scroll?: boolean;
};

export type UseScrollNavAction = React.Dispatch<UseScrollNavActionParams>;

export function useScrollNav(
  items?: UseScrollNavItem[],
  offset = 0,
): [UseScrollNavState, UseScrollNavAction] {
  const { headerHeight } = useGlobalStateContext();
  const yOffset = useMemo(() => headerHeight + offset, [headerHeight, offset]);
  const [activeItem, updateActive] = useReducer(
    (_, { id, scroll }: UseScrollNavActionParams): UseScrollNavState => {
      if (id && scroll) {
        document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
        history.replaceState({}, '', `#${id}`);
      }

      return id;
    },
    null,
  );
  const debouncedUpdate = useDebounce(
    (state: UseScrollNavActionParams) => updateActive(state),
    250,
  );

  const isSupported = useMemo(
    () => typeof window !== 'undefined' && 'IntersectionObserver' in window,
    [],
  );

  const observer = useRef<IntersectionObserver>(null);

  const observerCallback = useCallback<IntersectionObserverCallback>(
    entries => {
      entries.forEach(
        ({ boundingClientRect, isIntersecting, rootBounds, target }) => {
          const rootY = rootBounds?.y || 0;
          const anchorIndex = items.findIndex(({ node }) => node === target);
          const previousItemIndex = anchorIndex - 1;
          const previousItemRect =
            items[previousItemIndex]?.node?.getBoundingClientRect();

          // don't update if previous item is still visible when scrolling down
          if (
            previousItemRect?.top >= rootY &&
            previousItemRect?.bottom <=
              (window.innerHeight || document.documentElement.clientHeight)
          ) {
            return;
          }

          if (
            (anchorIndex === 0 && isIntersecting) ||
            boundingClientRect.y < rootY
          ) {
            updateActive({ id: items[anchorIndex]?.id, scroll: false });

            return;
          }

          if (boundingClientRect.y < window.innerHeight) {
            updateActive({
              id: items[previousItemIndex]?.id || null,
              scroll: false,
            });
          }
        },
      );
    },
    [items, updateActive],
  );

  useEffect(() => {
    if (window.location.hash) {
      debouncedUpdate({
        id: window.location.hash.replace('#', ''),
        scroll: true,
      });
    }
  }, []);

  useEffect(() => {
    const disconnect = () => observer?.current?.disconnect();

    if (!isSupported || !items?.length) {
      disconnect();
      return;
    }

    if (observer?.current) {
      disconnect();
    }

    observer.current = new IntersectionObserver(observerCallback, {
      rootMargin: `-${yOffset}px 0px 0px 0px`,
      threshold: 1,
    });

    items.forEach(({ node }) => observer.current.observe(node));

    return () => {
      disconnect();
    };
  }, [isSupported, items, observerCallback, yOffset]);

  return [activeItem, debouncedUpdate];
}
